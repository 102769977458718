<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <tr v-on="on" v-bind="attrs">
        <th class="text-right">{{ title }}</th>
        <td class="text-left">{{ value }}</td>
      </tr>
    </template>
    <span>{{ description }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipedRow',
  props: {
    title: String,
    value: Number,
    description: String,
  },
};
</script>
