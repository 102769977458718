<template>
  <div class="d-flex flex-column">
    <div>
      <v-subheader>Participant {{ analysis.participant }}</v-subheader>
    </div>

    <div>
      <p class="title">Distribution longueur des chaines</p>
      <apexchart type="bar" :options="chartOptions" :series="dependencyLengthSeries"></apexchart>
    </div>

    <div>
      <p class="title">Distribution des <i>verbal heads</i></p>
      <apexchart type="bar" :options="chartOptions" :series="verbalHeadsSeries"></apexchart>
    </div>

    <div >
      <p class="title">Déictiques</p>
      <apexchart type="radialBar" :options="deicticsChartOptions"
        :series="[analysis.deicticsSelf*100,
          analysis.deicticsOther*100,
          analysis.deicticsSpatiotemporal*100,
          analysis.deicticsDesignation*100]">
      </apexchart>
    </div>

    <div height="1000">
      <p class="title">Relations</p>
      <apexchart height="800" :options="relationsOptions" :series="relationsSeries"></apexchart>
    </div>

    <div>
      <p class="title">Disfluences ({{ this.analysis.disfluences.ratio * 100 | round }}%)</p>
      <apexchart type="bar" :options="disfluencesOptions" :series="disfluencesSeries"></apexchart>
      <p class="subtitle">Évolution</p>
      <p class="overline text-center">Nombre de disfluences</p>
      <p class="caption text-center font-weight-bold">
        Max: {{ this.analysis.disfluences.histogram.max}}
        / Min: {{ this.analysis.disfluences.histogram.min }}
      </p>
      <v-sparkline
        :value="this.analysis.disfluences.histogram.data"
        :smooth="2" fill type="trend" auto-line-width
        auto-draw>
      </v-sparkline>
    </div>

    <p class="title">Général</p>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Donnée</th>
            <th class="text-left">Valeurs</th>
          </tr>
        </thead>
        <tbody>
          <TooltipedRow
            title="Nombre de subordonnées relatives"
            :value="analysis.subordinates"
            description="Nombre de subordonnant avec la relation 'fixed' ou 'mark'"
          />
          <TooltipedRow
            title="Nombre de questions"
            :value="analysis.questions"
            description="Tours de paroles se terminant par '?'"
          />
          <TooltipedRow
            title="Questions / tour"
            :value="Math.round(analysis.questionsRatio*1000) / 1000"
            description="Nombre moyen de questions par tour de ce participant"
          />
          <TooltipedRow
            title="Arité moyenne des verbes"
            :value="analysis.verbalArity"
            description="Nombre moyen de relations partant d'un verbe"
          />
          <TooltipedRow
            title="Densité lexicale"
            :value="analysis.lexicalDensity * 100"
            description="Rapport du nombre de mots sans les stopwords sur le nombre total de mots"
          />
          <TooltipedRow
            title="Plus grande chaîne"
            :value="analysis.longestDependencyString"
            description="Profondeur max. de l'arbre syntaxique"
          />
          <TooltipedRow
            title="Pre-verbal order"
            :value="analysis.verbalOrder.pre"
            description="Occurences de sujet placé avant le verbe"
          />
          <TooltipedRow
            title="Post-verbal order"
            :value="analysis.verbalOrder.post"
            description="Occurences de sujet placé aprés le verbe"
          />
          <TooltipedRow
            title="Pre-object order"
            :value="analysis.objectOrder.pre"
            description="Occurences d'objet placé avant le verbe"
          />
          <TooltipedRow
            title="Post-object order"
            :value="analysis.objectOrder.post"
            description="Occurences d'objet placé après le verbe"
          />
          <TooltipedRow
            title="Ratio lemmes/tokens"
            :value="analysis.typesTokensRatio"
            description="Rapport du nombre de lemmes uniques sur le nobmre de tokens"
          />
          <TooltipedRow
            title="Ratio formes/tokens"
            :value="analysis.formsTokensRatio"
            description="Rapport du nombre de formes uniques sur le nobmre de tokens"
          />
          <TooltipedRow
            title="Ratio racine verbale"
            :value="analysis.verbalRootsRatio"
            description="Pourcentage de tour de parole où le verbe est la racine"
          />
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import TooltipedRow from './TooltipedRow.vue';

export default {
  name: 'SingleAnalysis',
  components: { TooltipedRow, apexchart: VueApexCharts },
  props: {
    analysis: Object,
    normalized: String,
  },
  data: () => ({
    deicticsChartOptions: {
      plotOptions: {
        radialBar: {
          hollow: {
            size: '20%',
          },
        },
      },
      legend: { show: true },
      labels: ['Soi', 'Autre', 'Spatiotemporel', 'Désignation'],
    },
  }),
  computed: {
    dependencyLengthSeries() {
      return [{
        name: 'Chaine de dépendance',
        data: this.analysis.dependencyLengthDistribution,
      }];
    },

    verbalHeadsSeries() {
      return [{
        name: 'verbal heads',
        data: this.analysis.verbalHeadsDistribution,
      }];
    },

    relationsSeries() {
      const data = this.analysis.dependenciesDistribution.map((x) => Math.round((x.data * 100.0)));
      return [{
        data,
      }];
    },

    disfluencesSeries() {
      return [{
        data: [
          this.analysis.disfluences.total,
          this.analysis.disfluences.repeat,
          this.analysis.disfluences.euh,
          this.analysis.disfluences.correction,
          this.analysis.disfluences.fragment,
        ],
      }];
    },

    relationsOptions() {
      return {
        chart: { type: 'bar', zoom: { enabled: true } },
        plotOptions: { bar: { horizontal: true } },
        xaxis: {
          labels: { formatter: (value) => (`${value}%`) },
          tickAmount: 2,
          max: 100,
          categories: this.analysis.dependenciesDistribution.map((x) => (x.name)),
          tickPlacement: 'on',
        },
        dataLabels: {
          offsetX: 5,
          formatter: (value) => (`${value}%`),
          style: {
            colors: ['#4F4F4F'],
          },
        },
      };
    },

    disfluencesOptions() {
      return {
        chart: {
          type: 'bar',
          height: 350,
          zoom: { enabled: true },
        },
        plotOptions: { bar: { horizontal: true } },
        dataLabels: { enabled: true },
        legend: { show: true },
        xaxis: {
          categories: [
            'Total',
            'Répétitions',
            'Euh',
            'Reprises + corrections',
            'Fragments',
          ],
          tickPlacement: 'on',
        },
      };
    },

    chartOptions() {
      return {
        height: 100,
        yaxis: {
          max: this.normalized === 'yes' ? 1 : undefined,
          title: { text: this.normalized === 'yes' ? '' : 'Quantité' },
          tickAmount: 2,
        },
        dataLabels: {
          enabledOnSeries: true,
        },
        style: { fontSize: this.normalized === 'yes' ? '6px' : '14px' },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
            },
          },
        },
        xaxis: {
          categories: Array.from(
            Array(this.analysis.dependencyLengthDistribution.length).keys(),
          ),
          tickPlacement: 'on',
          title: { text: 'Longueur' },
        },
      };
    },

  },
};
</script>
